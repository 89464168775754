
import React from 'react';
import {useState} from "react"
import {useNavigate} from "react-router-dom";
import './Login.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Footer from './Footer'

const AdminLogin = () => {
    const navigate = useNavigate();
    const [username, setusername] = useState()
    const [password, setpassword] = useState()
    const [errormsg, seterrormsg] = useState()

    function handleLogin(e){
        e.preventDefault();
        if(username !== 'admin' && password !=='123456')
           seterrormsg("Please enter correct username/password.")
        else if(username !== 'admin')
           seterrormsg("Please enter correct username.")
        else if(password !== '123456')
           seterrormsg("Please enter correct password.")
        else{
            sessionStorage.setItem('islogin', true);
            sessionStorage.removeItem('dtpId');
            navigate("/admin/dashboard",{replace: true,});
        }
          

    }


    return (
        <div >
        <div className="hr-main backgrounddiv">
                <div className="container-login">
        <div className="login-content">
                        
        <h4 className="text-center"><img src="Images/idea.png" height="40px" alt=''/> Admin</h4>
        <form id="login" onSubmit={handleLogin}   >
            <div className="">
                <label for="name" className="label">Username  </label>
                
                <input type="text" className="form-control"    id="username" name="username" placeholder="Enter username" value={username} 
                      onChange={e => setusername(e.target.value)}
                      required />
            </div>
    
            <div className="form-group">
                <label for="password" className="label">Password</label>
                <input type="password" className="form-control" id="password" name="password"
                onChange={e => setpassword(e.target.value)}
                       placeholder="Enter password"  value={password}   required/>
    
            </div>

            <div className="error">{errormsg}</div>
    
            <div className="topmargin text-center">
                <input type="submit" id="submit" name="submit" className="btn " value="Login"/>
            </div>
    
    
        </form>
        </div>
        </div>
    </div>
            <Footer/>
            
    </div>
    );



};
export default AdminLogin