export const LOGINURL = 'https://hrmsqa.collective-s.in/api/japi.php';
export const BASEURL = 'https://trainingqa.csdev.live/api/learningjapi.php';
export const LOGINTAG="login";
export const ADDPLATFORMHOURSTAG="addLearningHoursTask";
export const GETPLATFORMTAG="getPlatform";
export const GETADMINDASHBOARDRTAG="getYearwiseTotalHours";
export const GETLEARNINGREPORTTAG="getYearEmpLearningHours";
export const GETLEARNINGDETAILSTAG="viewYearEmpLearningTasks";
export const GETEMPLEARNINGTASKTAG="getEmpLearningDetails";
export const GEEMPLISTTAG="getEmp";
export const GETALLEMPTAG="getEMPList";
export const SYNCDATA="syncAllEmp";
export const ADDSOURCE='addSource';
export const GETSOURCE="getSource"
export const VIEWSOURCE="viewSource";