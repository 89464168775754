import React from 'react';
import './Login.css';
import * as myConst from './const.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Footer from './Footer'

function Login() {
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [errormsg, setErrorMsg] = useState("");
  const [addclass, setClass] = useState("");
  
   const navigate = useNavigate();
  function onlynumber(event){
    if (!/[0-9]/.test(event.key)) {event.preventDefault();
 }
}

  function handleLogin(e) {
    e.preventDefault();
    if (!mobileNo && password) { setErrorMsg("Enter Mobile number"); } 
    else if (mobileNo && !password) { setErrorMsg("Enter Password"); } 
    else if (!mobileNo && !password) { setErrorMsg("Enter Mobile number & Password"); } 
    else { 
      
    setClass("button--loading");
        const userobj = new FormData();
           userobj.append("tag", myConst.LOGINTAG);
           userobj.append("mobileNo", mobileNo);
           userobj.append("password", password);



     axios.post(myConst.LOGINURL, userobj)
        .then((response) => {
          //console.log(response.data);
          if(response.status === 200){
            setClass("");
            if (response.data.success === "true") {
              sessionStorage.setItem("dtpId",response.data.dtpId);
              sessionStorage.setItem('userlogin', true);
              sessionStorage.setItem('empCode', response.data.empCode);
                navigate("/addtraining",{replace: true,});
                
              } else if (response.data.success === "false") {
                setErrorMsg(response.data.message);
              } else if (response.data.success === "fail") {
                setErrorMsg(response.data.message);
              }
          }
          setClass("");
        })
        .catch((error) => {
          setClass("");
          console.log(error);
        });
        
        

     }
  }

    return (
      <div>
                <div className="hr-main backgrounddiv">
                <div className="container-login">
        <div className="login-content">
                        
        <h4 className="text-center"><img src={require('./Images/idea.png')} height="30px" alt=''/>
         My Training Login</h4>
        <form id="login"  onSubmit={handleLogin} >
            <div style={{marginTop:'10px'}}>
                <label className="label">Mobile Number  </label>
                
                <input type="text" className="form-control" onChange={(e)=>setMobileNo(e.target.value)} onKeyPress={(event) => onlynumber(event)}  id="mobileNo" name="mobileNo" placeholder="Enter contact number "
           maxLength={10} required/>
            </div>
    
            <div className="topmargin">
                <label for="password" className="label">Password</label>
                <input type="password" className="form-control" id="password" name="password"
                       placeholder="Enter password" onChange={(e)=>setPassword(e.target.value)} required/>
    
            </div>

            <div className="error">{errormsg}</div>
            <div className="topmargin text-center">
            <button type="submit" id="submit" name="submit" className={"btn button "+ addclass}>
    <span class="button__text">Login</span>
</button>
             </div>
    
    
        </form>
        </div>
        </div>
    </div>
    <Footer/>
            
    </div>
      );
    }
    
    export default Login;