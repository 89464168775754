import React from "react";
import './Login.css';
import {useNavigate} from "react-router-dom";
function AdminHeader() {
  
  const navigate = useNavigate();

 function logOut () {
    sessionStorage.clear();
    navigate("/admin/login",{replace: true,});
    window.location.reload();
        
  };

  return (
    <nav className="navbar navbar-default navbar-expand-lg static-top">
    <a className="navbar-brand" href="#">
      <img className="headerlogo" src={require('./Images/collectivesLogo.png')} alt="..." height="36" />
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div style={{'marginRight':'20px'}} className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <a className="nav-link" aria-current="page" href="/admin/dashboard">Dashboard</a>
        </li>
        <li><div className="dropdown">
                            <button className="dropbtn">Library</button>
                      <div className="dropdown-content">
                          <a href="/admin/source">Add Library</a>
                          <a href="/admin/viewsource">Library List</a>
                          

                      </div>
                    </div></li>
        <li className="nav-item">
          <a className="nav-link" aria-current="page" href="/admin/sync">Sync</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" onClick={logOut} href="#">Logout</a>
        </li>
      </ul>
    </div>
</nav>
  );
}
export default AdminHeader;