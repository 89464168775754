import React from 'react';
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from "react";
import AdminHeader from './AdminHeader'
import Header from './Header'
import Footer from './Footer'
import { useParams } from "react-router-dom";
import * as myConst from './const.js'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {decode as base64_decode} from 'base-64';

function ViewReportDetails() {
  const marginb = {
    'margin-bottom':'3%'
  }
  const dtpId = sessionStorage.getItem('dtpId');
  let param = useParams();
  let empCode = base64_decode(param.id === undefined ? "" : param.id);
  const year = base64_decode(param.year);
  const [learningTaskList, setLearningTaskList] = useState([])
  const [emplist, setEmpList] = useState([])
  const islogin = sessionStorage.getItem('islogin')
  const userlogin = sessionStorage.getItem('userlogin')
  const [empcode, setempcode] = useState(empCode)
  const [selectedValue, setOption] = useState("")
  const navigate = useNavigate();

  useEffect(() => {
    if (userlogin === null && dtpId === "2") navigate("/");
    else if (islogin === null && dtpId == null) navigate("/admin/login");
    else{
      getemplist();
      getlearninglist();
    }
      
  }, [])



  function getemplist() {
    const inputData = new FormData();

    inputData.append("tag", myConst.GEEMPLISTTAG);
    
    axios
      .post(myConst.BASEURL, inputData)
      .then((response) => {
       // console.log(response.data);
        if (response.status === 200) {
          let list = response.data.map((item, index) => {
            return (
                <option key={index} value={item.empCode}>{item.empName}</option>
            )
        })
          setEmpList(list)
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }


  function getlearninglist() {
    const inputData = new FormData();

    inputData.append("tag", myConst.GETLEARNINGDETAILSTAG);
    inputData.append("year", year);
    if ((empcode === undefined || empcode === "")) {
      inputData.append("empCode", "");
    } else {
      inputData.append("empCode", empcode);

    }
    axios
      .post(myConst.BASEURL, inputData)
      .then((response) => {
        //console.log(response.data);
        if (response.status === 200) {
          setLearningTaskList(response.data)
        }
        //TotalHours =  response.data.reduce((result,number)=> result+number.duration);
      })
      .catch((error) => {
        console.log(error);
      });

    //  TotalHours = learningTaskList.reduce(function(prev, current) {
    //     return prev + +current.duration
    //   }, 0);
  }

  function handleChange(event) {
    setOption(event.target.value);
    setempcode(event.target.value);
}



  return (
    <div>
      {dtpId === "2" ? (<Header />) : <AdminHeader />}
      <div className="hr-main">
        <div className="container-fluid">
          <div className="container-login_2">
              <h3 className="text-center">
                <img src={require('./Images/clipboard.png')} height="40px" />Report Details</h3>
          


            {islogin != null ? (<div className="row">
              <div className="form-group col-md-5" ></div>
              <div className="form-group col-md-2" >

                <label>Select Employee:</label>
                <select value={selectedValue} className="form-control" name='SelectEmployee' onChange={handleChange}>

                <option value="">Select  </option>
                {emplist}
                </select>

              </div>
              <div className="form-group text-right col-md-2">
                <br></br>
                <input type="submit" id="submit" name="submit" value="Submit" className='btn btn-primary' onClick={getlearninglist} />
                  
              </div>

            </div>):''}

            <div style={marginb} className="table-responsive doc-search-tbl">



              <table className="table table-bordered table-striped">
                <thead>


                  <tr style={{ textAlign: 'center' }}>
                    <th>Employee Name</th>
                    <th>Training  Name</th>
                    <th>Date</th>
                    <th>Duration(Hours)</th>
                    <th style={{width:'350px' }}>Description</th>

                  </tr>
                </thead>

                <tbody>

                  {learningTaskList.map((list, index) =>
                    <tr>
                      <td>{list.empName}</td>
                      <td>{list.platformName}</td>
                      <td>{list.date}</td>
                      <td>{list.TotalHours}</td>
                      <td>{list.learningDetails}</td>


                    </tr>
                  )}



                  <tr><td colSpan="3"><b> Total Duration </b></td>
                    <td colSpan="1"> <b>{learningTaskList.reduce(function (prev, current) {
                      return prev + +current.TotalHours
                    }, 0)}</b></td>
                  </tr>

                </tbody>

              </table>



            </div>
          </div>
        </div>
        <Footer />
      </div>

      <div className="push"></div>
    </div>
  );
}

export default ViewReportDetails;