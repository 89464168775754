import React from 'react';
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from "react";
import axios from "axios";
import * as myConst from './const.js'
import Header from './Header'
import Footer from './Footer'
import { useNavigate } from "react-router-dom";

function AddLearningTask() {
    const [addclass, setClass] = useState("");
    const [hideAddNew, setHideAddNew] = useState(false);
    const [platformList, setPlatformList] = useState([]);

    const [platformId, setPlatformId] = useState("");
    const [platformName, setPlatformName] = useState("");
    const [date, setDate] = useState(new Date().toLocaleDateString('en-ca'));
    const [duration, setDuration] = useState("");
    const [learningDetails, setLearningDetails] = useState("");

    const [error, setError] = useState(false);

    const navigate = useNavigate();

    const userlogin = sessionStorage.getItem('userlogin')

    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            window.history.go(1);
          });
        if (userlogin === null) navigate("/");
        else
            getplatformlist();
    }, [])


    function getplatformlist() {
        const inputData = new FormData();
        inputData.append("tag", myConst.GETPLATFORMTAG);
        // console.log("dropdown")
        axios
            .post(myConst.BASEURL, inputData)
            .then((response) => {

                setPlatformList(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    function SubmitLearningTask(e) {
        e.preventDefault();
        let empCode = sessionStorage.getItem('empCode');

        const inputData = new FormData();
        inputData.append("tag", myConst.ADDPLATFORMHOURSTAG);
        inputData.append("empCode", empCode);
        inputData.append("platformId", platformId);
        inputData.append("platformName", platformName);
        inputData.append("date", date);
        inputData.append("duration", duration);
        inputData.append("learningDetails", learningDetails);

        console.log(inputData);

        setClass("button--loading");

        axios
            .post(myConst.BASEURL, inputData)
            .then((response) => {

                console.log(response);
                if (response.status === 200) {
                    if (response.data !== null) {
                        setError(true);
                        setTimeout(() => {
                            navigate("/learningtask");
                            setClass("");
                        }, 1000);
                        
                    }
                }
            })
            .catch((error) => {
                setClass("");
                console.log(error)
            });
    }


    const getCurrentDateInput = () => {

        const dateObj = new Date();

        // get the month in this format of 04, the same for months
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObj.getDate()).slice(-2);
        const year = dateObj.getFullYear();

        const shortDate = `${year}-${month}-${day}`;
        return shortDate;
    };

    function handleChange(value) {
        setPlatformId(value);
        if (value === "0")
            setHideAddNew(true)
        else
            setHideAddNew(false)

    }
    function onlynumber(event) {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }



    return (
        <div>
            <Header />
            <div className="hr-main backgrounddiv">
                <div  >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-group col-md-4" style={{ zIndex: -1 }} />
                            <div className="form-group col-md-4" >

                                <div className="login-content" style={{ zIndex: -1 }}>

                                    <h2 className="text-center"> <img src={require('./Images/clipboard.png')} height="40px" /> Training Completed</h2>
                                    {error && <label style={{ color: 'green' }}>Training Added Successfully</label>}
                                    <form onSubmit={SubmitLearningTask}>



                                        <div className="form-group">
                                            <label>Select Training:</label>
                                            <select className="form-control" required id="platformId" name="platformId"  onChange={(e) => { handleChange(e.target.value); }}  >
                                                <option hidden value="">Select Name</option>
                                                <option value="0">Add New</option>

                                                {
                                                    platformList.map(value => <option value={value.platformId} key={value.platformId}>{value.platformName}</option>)
                                                }



                                            </select>
                                        </div>

                                        {hideAddNew && (<div className="form-group" id="hideshowplatformName">
                                            <label>Add New Training</label>
                                            <input type="text" className="form-control" id="trainingName" name="trainingName" onChange={(e) => { setPlatformName(e.target.value); }}
                                                placeholder="Enter Training Name" required />
                                        </div>)}
                                        <div className="form-group">
                                            <label>Date</label>
                                            <input type="date" className="form-control" id="date" name="date"
                                                placeholder="Enter date" max={getCurrentDateInput()} onkeydown="return false" defaultValue={getCurrentDateInput()} onChange={(e) => { setDate(e.target.value); }} required />
                                        </div>

                                        <div className="form-group">
                                            <label>Duration(Hours)</label>
                                            <input type="text" className="form-control" id="duration" name="duration" onKeyPress={(event) => onlynumber(event)}
                                                placeholder="Enter Duration" onChange={(e) => { setDuration(e.target.value); }} required />
                                        </div>

                                        <span id="errorMsg" style={{ color: 'red' }}></span>
                                        <div className="form-group">
                                            <label >Description</label>
                                            <textarea className="form-control" id="learningDetails" maxLength={2000} name="learningDetails" placeholder="Enter task details " onChange={(e) => { setLearningDetails(e.target.value); }} required cols={7} rows={7}></textarea>
                                        </div>

                                        <div className="from-group text-center">

                                            <button type="submit" id="submit" name="submit" className={"btn button " + addclass}>
                                                <span class="button__text">Submit</span>
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className="form-group col-md-4"> </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AddLearningTask;