import React, {useEffect, useState } from 'react';
import './Login.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import * as myConst from './const.js'
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import { useNavigate } from "react-router-dom";

const ViewLearningTask = () => {
    const cuyear = (new Date()).getFullYear();
    const year = (new Date()).getFullYear() - 2;
    const years = Array.from(new Array(3), (val, index) => index + year);
    let list = years.map((item, index) => {
        return (
            <option key={index} >{item}</option>
        )
    })
    const [addclass, setClass] = useState("");
    const [selectedYear, setOption] = useState(cuyear)
    const [getlist, setlist] = useState([])
    const userlogin=sessionStorage.getItem('userlogin')
    const navigate=useNavigate();
    useEffect(()=>{
         if(userlogin === null)navigate("/");
        else
        getemphours(1);
   },[])

   function handleChange(event) {
    setOption(event.target.value)
}
    function getemphours(from) {
        if(from !== 1){

            setClass("button--loading");
        }
        let empCode=sessionStorage.getItem('empCode');
        //console.log(empCode)
        const inputData = new FormData();
        inputData.append("tag", myConst.GETEMPLEARNINGTASKTAG);
        inputData.append("empCode", empCode);
        inputData.append("year", selectedYear);
        axios.defaults.timeout = 5000 // 5seconds;
        
        axios.post(
            myConst.BASEURL,
            inputData,
        ).then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                setClass("");
                setlist(response.data);
            }
            setClass("");
        })
            .catch(error => {
                setClass("");
                console.log(error);
            });
    }

    return (
        <div>
    <Header />
        <div className="hr-main">
            <div className="container-fluid">

                <div className="filterDiv">
                    <div className="topmargin">
                    </div>

                    <h3 className="text-center">
                        <img src={require('./Images/clipboard.png')} alt='' width="30" height="30"></img>
                        Training Completed</h3>

                    <div className='padding'></div>
                  
                </div>
                
                <div className='login-content'>
                <div className="row">
                        <div className="form-group col-md-2" ></div>
                        <div className="form-group col-md-4" >

                            <label>Select Year:</label>
                            <select value={selectedYear} className="form-control" name='selectedYear' onChange={handleChange}>

                                <option value="">Select  </option>
                                {list}

                            </select>

                        </div>
                        <div className="form-group text-right col-md-2">
                            <br></br>
                            <button type='button' className={"btn button "+ addclass} onClick={getemphours}>
                            <span class="button__text">Submit</span>
                            </button >
                        </div>
                        
                    </div>
                <div className='padding'></div>
                <div>
                <div className="table-responsive doc-search-tbl">

                    <table className="table table-bordered table-striped">
                        <thead>


                            <tr >
                                <th className="textsize text-center">Sr.No</th>
                                <th className="textsize text-center">Training Name</th>
                               
                                <th className="textsize text-center">Duration</th>

                            </tr>
                        </thead>
                        <tbody>
                            {getlist.length === 0 ? '' :getlist.map((item, index) => (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item.platformName}</td>
                                    <td>{item.TotalHours}</td>
                                </tr>
                            ))}
                           
                    {getlist.length === 0 ? (<tr><td colspan="3"><div className="text-center">
                        <h6 className='error'>No Data Found</h6></div></td></tr>) : ''}
                    
                            <tr><td colspan="2"><b> Total Duration </b></td>
                                <td colspan="2"> <b>{getlist.reduce(function(prev, current) {
      return prev + +current.TotalHours
    }, 0)}</b></td>
                        </tr>
                        </tbody>

                    </table>

                </div>
                </div>
                </div>


                </div>
            <Footer/>


            </div>
        </div>
    );
};
export default ViewLearningTask