import React, {useEffect, useState } from 'react';
import './Login.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import * as myConst from './const.js'
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import { useNavigate } from "react-router-dom";
import {encode as base64_encode} from 'base-64';

const ViewLearningReport = () => {
    const height={
        'height':'350px',
      }
    const [addclass, setClass] = useState("");
    const cuyear = (new Date()).getFullYear();
    const year = (new Date()).getFullYear() - 2;
    const years = Array.from(new Array(3), (val, index) => index + year);
    let list = years.map((item, index) => {
        return (
            <option key={index} >{item}</option>
        )
    })
    const [selectedYear, setOption] = useState(cuyear)
    const [getlist, setlist] = useState([])
    const userlogin=sessionStorage.getItem('userlogin')
    const navigate=useNavigate();
    
    useEffect(()=>{
         if(userlogin === null)navigate("/");
        else
        getemphours(1);
   },[])

    function handleChange(event) {
        setOption(event.target.value)
    }

    function getemphours(from) {
        if(from !== 1){

            setClass("button--loading");
        }
        const getData = new FormData();
        getData.append("tag", myConst.GETLEARNINGREPORTTAG);
        getData.append("year", selectedYear);

        axios.defaults.timeout = 5000 // 5seconds;
        axios.post(
            myConst.BASEURL,
            getData,
        ).then((response) => {
            console.log(response.status, response.data.token);
            if (response.status === 200) {
                setClass("");
                //console.log(response.data);
               setlist(response.data);
            }
            setClass("");
        })
            .catch(error => {
                setClass("");
                console.log(error);
            });
    }

    return (
        <div>
        <Header />
        <div className="hr-main">
            <div className="container-fluid">
           
            <div class="login-content">
                    

                    <h3 className="text-center">
                        <img src={require('./Images/clipboard.png')} alt='' width="30" height="30"></img>
                        Yearly Report</h3>

                    <div className="row">
                        <div className="form-group col-md-2" ></div>
                        <div className="form-group col-md-4" >

                            <label>Select Year:</label>
                            <select value={selectedYear} className="form-control" name='selectedYear' onChange={handleChange}>

                                <option value="">Select  </option>
                                {list}

                            </select>

                        </div>
                        <div className="form-group text-right col-md-2">
                            <br></br>
                            <button type='button' className={"btn button "+ addclass} onClick={getemphours}>
                            <span class="button__text">Submit</span>
                            </button >
                        </div>
                        
                    </div>
                
                
                <div style={height} className="table-responsive doc-search-tbl">

                    <table className="table table-bordered table-striped">
                        <thead>


                            <tr>
                                <th className="textsize">Sr.No</th>
                                <th className="textsize">Employee Name</th>
                                <th className="textsize">Duration</th>

                            </tr>
                        </thead>
                        <tbody>
                            {getlist.length == 0 ? '' : getlist.map((item, index) => (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item.empName}</td>
                                    <td><a href={"/reportDetails/"+base64_encode(selectedYear.toString())+"/"+base64_encode(item.empCode)}>{item.TotalHours}</a></td>
                                </tr>
                            ))}
                        </tbody>
                       

                    </table>
                    <div className="text-center">
                    {getlist.length === 0 ? (<h6 className='error'>No Data Found</h6>) : ''}
                    </div>

                </div>
                
                </div>




            </div>
            </div>
            <Footer/>
        </div>
    );
};
export default ViewLearningReport