import React from 'react';
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRef, useEffect } from "react";
import axios from "axios";
import * as myConst from './const.js'
import { useNavigate } from "react-router-dom";


function SyncEmp() {
  const initialized = useRef(false)
  const navigate = useNavigate();

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      syncGetData();
    }


  })

  function syncGetData() {
    const inputData = new FormData();
    inputData.append("tag", myConst.GETALLEMPTAG);
    inputData.append("lastEmpId", "0");
    axios
      .post(myConst.LOGINURL, inputData)
      .then((response) => {
       // console.log(response.data)
        if (response.status === 200) {
          syncAdddata(response.data);


        }
      })
      .catch((error) => {
        console.log(error)
      });
  }


  function syncAdddata(data) {

    console.log(data.length);

    const userobj = new FormData();
    userobj.append("tag", myConst.SYNCDATA);
    userobj.append("alldata", JSON.stringify(data));

    axios.post(myConst.BASEURL, userobj)
      .then((response) => {
        //console.log(response.data)
        navigate("/admin/dashboard");
      })
      .catch((error) => {
        console.log(error);
      });

  }

  return (
    <>

    </>
  );
}

export default SyncEmp;