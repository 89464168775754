import React from 'react';
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from "react";
import axios from "axios";
import * as myConst from './const.js'
import AdminHeader from './AdminHeader';
import Footer from './Footer'
import { useNavigate } from "react-router-dom";

function AddSource() {
    const [addclass, setClass] = useState("");
    const [hideAddNew, setHideAddNew] = useState(false);
    const [sourceList, setsourceList] = useState([]);

    const [sourceId, setSourceId] = useState("");
    const [sourceName, setSourceName] = useState("");
    const [title, setTitle] = useState("");
    const [link, setLink] = useState("");
    const [description, setDescription] = useState("");

    const [error, setError] = useState(false);

    const navigate = useNavigate();

    const userlogin = sessionStorage.getItem('islogin')

    useEffect(() => {
        if (userlogin === null) navigate("/admin/login");
        else
            getsourcelist();
    }, [])


    function getsourcelist() {
        const inputData = new FormData();
        inputData.append("tag", myConst.GETSOURCE);
        axios
            .post(myConst.BASEURL, inputData)
            .then((response) => {
                //console.log(response.data)
               if(response.status === 200){
                  setsourceList(response.data)
               }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    function SubmitLearningTask(e) {
        e.preventDefault();
        const inputData = new FormData();
        inputData.append("tag", myConst.ADDSOURCE);
        inputData.append("sourceId", sourceId);
        inputData.append("sourceName", sourceName);
        inputData.append("link", link);
        inputData.append("title", title);
        inputData.append("description", description);

        //console.log(inputData);

        setClass("button--loading");

        axios
            .post(myConst.BASEURL, inputData)
            .then((response) => {

                console.log(response);
                if (response.status === 200) {
                    if (response.data !== null) {
                        setError(true);
                        setTimeout(() => {
                            navigate("/admin/viewsource");
                            setClass("");
                        }, 1000);
                        
                    }
                }
            })
            .catch((error) => {
                setClass("");
                console.log(error)
            });
    }

    function handleChange(value) {
        setSourceId(value);
        if (value === "0")
            setHideAddNew(true)
        else
            setHideAddNew(false)

    }
   

    return (
        <div>
            <AdminHeader />
            <div className="hr-main backgrounddiv">
                <div  >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-group col-md-4" style={{ zIndex: -1 }} />
                            <div className="form-group col-md-4" >

                                <div className="login-content" style={{ zIndex: -1 }}>

                                    <h3 className="text-center"> <img src={require('./Images/clipboard.png')} height="35px" /> Add Library</h3>
                                    {error && <label style={{ color: 'green' }}>Library Added Successfully</label>}
                                    <form onSubmit={SubmitLearningTask}>
                                        <div className="form-group">
                                            <label>Select Source:</label>
                                            <select className="form-control" required id="sourceId" name="sourceId"  onChange={(e) => { handleChange(e.target.value); }}  >
                                                <option hidden value="">Select Name</option>
                                                <option value="0">Add New</option>

                                                {
                                                    sourceList.map(value => <option value={value.sourceId} key={value.sourceId}>{value.sourceName}</option>)
                                                }



                                            </select>
                                        </div>

                                        {hideAddNew && (<div className="form-group" id="hideshowplatformName">
                                            <label>Add New Source</label>
                                            <input type="text" className="form-control" id="sourceName" name="sourceName" onChange={(e) => { setSourceName(e.target.value); }}
                                                placeholder="Enter Training Name" required />
                                        </div>)}
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input type="text" className="form-control" id="title" name="title"
                                                placeholder="Enter Title" onChange={(e) => { setTitle(e.target.value); }} required />
                                        </div>

                                        <div className="form-group">
                                            <label>Link</label>
                                            <input type="text" className="form-control" id="link" name="link"
                                                placeholder="https://example.com" onChange={(e) => { setLink(e.target.value); }} pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" title='Provided url not valid' required />
                                        </div>

                                        <span id="errorMsg" style={{ color: 'red' }}></span>
                                        <div className="form-group">
                                            <label >Description</label>
                                            <textarea className="form-control" id="description" maxLength={2000} name="description" placeholder="Enter Description " onChange={(e) => { setDescription(e.target.value); }} required cols={7} rows={7}></textarea>
                                        </div>

                                        <div className="from-group text-center">

                                            <button type="submit" id="submit" name="submit" className={"btn button " + addclass}>
                                                <span class="button__text">Submit</span>
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className="form-group col-md-4"> </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AddSource;