import React from "react";
import './Login.css';
import {useNavigate} from "react-router-dom";
function Header() {
  const navigate = useNavigate();
    //sessionStorage.getItem('isRole')
const dtpId=sessionStorage.getItem('dtpId');
 function logOut () {
      
    sessionStorage.clear();
    navigate("/",{replace: true,});
    window.location.reload();
        
  };

  return (
    <nav className="navbar navbar-default navbar-expand-lg static-top">
    <a className="navbar-brand" href="#">
      <img className="headerlogo" src={require('./Images/collectivesLogo.png')} alt="..." height="36" />
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div style={{'marginRight':'20px'}} className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto">
      <li className="nav-item">
          <a className="nav-link"  href="/addtraining">Home</a>
        </li>
        <li className="nav-item">
       <a className="nav-link" aria-current="page" href="/learningtask">Training</a>
     </li>
     <li className="nav-item">
          <a className="nav-link"  href="/viewsource">Library</a>
        </li>
      {dtpId === "2" ?
       <li className="nav-item">
       <a className="nav-link" aria-current="page" href="/report">Report</a>
     </li> :''}
        <li className="nav-item">
          <a className="nav-link" onClick={logOut} href="#">Logout</a>
        </li>
      </ul>
    </div>
</nav>
  );
}
export default Header;