import React from 'react';
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from "react";
import AdminHeader from './AdminHeader'
import Header from './Header'
import Footer from './Footer'
import * as myConst from './const.js'
import axios from 'axios';

function ViewSource() {
  const marginb = {
    'margin-bottom':'3%'
  }
  const userlogin = sessionStorage.getItem('userlogin')
  const [search, setSearch] = useState("")
  const [emplist, setEmpList] = useState([])
  
  useEffect(() => {
      searchsourcelist();
  
  }, [search])

  function searchsourcelist() {
    const inputData = new FormData();

    inputData.append("tag", myConst.VIEWSOURCE);
    inputData.append("search", search);
    
    axios
      .post(myConst.BASEURL, inputData)
      .then((response) => {
        if(response.status === 200){
          setEmpList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }


  return (
    <div>
      {userlogin === null ? (<AdminHeader />) : <Header />}
      <div className="hr-main">
        <div className="container-fluid">
          <div className="container-login_2">
              <h2 className="text-center">
                <img src={require('./Images/clipboard.png')} height="40px" />Library List</h2>
          


            {/* {islogin != null ? ( */}
            <div className="row">
              <div className="form-group col-md-5" ></div>
              <div className="form-group col-md-3" >

                <label>Search:</label>
                <input placeholder="Search here" type="text" className="form-control" name='search' onChange={(e) => { setSearch(e.target.value); }}/>


              </div>
              {/* <div className="form-group text-right col-md-2">
                <br></br>
                <input type="submit" id="submit" name="submit" value="Submit" className='btn btn-primary' onClick={searchsourcelist} />
                  
              </div> */}

            </div>
            {/* ):''} */}

            <div style={marginb} className="table-responsive doc-search-tbl">



              <table className="table table-bordered table-striped">
                <thead>


                  <tr>
                    <th style={{ textAlign: 'center' }}>Source Name</th>
                    <th style={{ textAlign: 'center' }}>Title</th>
                    <th style={{ textAlign: 'center' }}>Link</th>
                    <th style={{ textAlign: 'center' }}>Description</th>

                  </tr>
                </thead>

                <tbody>

                  {emplist.map((list, index) =>
                    <tr>
                      <td>{list.sourceName}</td>
                      <td>{list.title}</td>
                      <td style={{color:'blue'}}> <a href={list.link} target='_blank'>Click Here</a> </td>
                      <td style={{width:'450px'}}>{list.description}</td>


                    </tr>
                  )} 


                </tbody>

              </table>



            </div>
          </div>
        </div>
        <Footer />
      </div>

      <div className="push"></div>
    </div>
  );
}
export default ViewSource;