import React from 'react';
import './App.css';
//import ViewLearningTask from './viewlearningtask'
import ViewReportDetails from './viewReportDetails'
import AdminDashboard from './admindashboard'
import AdminLogin from './adminlogin'
import Login from './Login'
import {BrowserRouter,Routes,Route} from "react-router-dom";
import AddLearningTask from './addLearningTask'
import NotFound from './NotFound'
import ViewLearningReport from './viewlearningreport';
import ViewLearningTask from './viewlearningtask';
import SyncEmp from './syncEmp';
import AddSource from './addSource';
import ViewSource from './viewSource';

function App() {
  return (
    <>
    <BrowserRouter>
    <Routes >
      <Route exact path="" element={<Login/>}/>
      <Route exact path="/addtraining" element={<AddLearningTask/>}/>
      <Route exact path="/admin/login" element={<AdminLogin/>}/>
      <Route exact path="/admin/dashboard" element={<AdminDashboard/>}/>
      <Route exact path="/admin/sync" element={<SyncEmp/>}/>
      <Route exact path="/admin/source" element={<AddSource/>}/>
      <Route exact path="/admin/viewsource" element={<ViewSource/>}/>
      <Route exact path="/reportDetails/:year" element={<ViewReportDetails/>}/>
      <Route exact path="/reportDetails/:year/:id" element={<ViewReportDetails/>}/>
      <Route exact path="/report" element={<ViewLearningReport/>}/>
      <Route exact path="/learningtask" element={<ViewLearningTask/>}/>
      <Route exact path="/viewsource" element={<ViewSource/>}/>
      <Route path="*" element={<NotFound/>} />
    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
