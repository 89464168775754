import React from 'react';
import {useEffect,useState } from "react";
import {useNavigate} from "react-router-dom";
import './Login.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import * as myConst from './const.js'
import axios from 'axios';
import AdminHeader from './AdminHeader'
import Footer from './Footer'
import {encode as base64_encode} from 'base-64';


const AdminDashboard = () => {
    const navigate = useNavigate();
    const year = (new Date()).getFullYear();
    const preyear = (new Date()).getFullYear() - 1;
    const [getadminhr, setAdminHr] = useState([]);
    const islogin=sessionStorage.getItem('islogin')
    useEffect(() => {
        window.addEventListener('popstate', (e) => {
            window.history.go(1);
          });
         if(islogin === null)navigate("/admin/login");
        else
          getcompanyhours();
          
        
    }, []);

    function getcompanyhours() {
        axios.defaults.timeout = 5000 // 5seconds;
        const inputData = new FormData();
          inputData.append("tag", myConst.GETADMINDASHBOARDRTAG);
        
        axios.post(
            myConst.BASEURL,
            inputData,
        ).then((response) => {
            if (response.status === 200) {
            console.log(response.data);
                setAdminHr(response.data);
            }
        })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <div>
             <AdminHeader />
        <div className="hr-main backgrounddiv">
            <div className="container-fluid">
           
            <div class="container-login">
        <div class="login-content">
                <div className="filterDiv">
                    
                    <h3 className="text-center">Dashboard</h3>
                    <div className='padding'></div>

               
                <div className='padding'></div>
                <div className="table-responsive doc-search-tbl">

                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <td className="textsize">Company total hours on training in {year}</td>
                                <td className="textsize"><a href={"/reportDetails/"+base64_encode(year.toString())}>{getadminhr.length !== 0 ?
                                 getadminhr.map((list,index) => list.Year === year.toString()? list.TotalHours : ''): 0}</a></td>

                            </tr>
                            <tr>
                                <td className="textsize">Company total hours on training in {preyear}</td>
                                <td className="textsize"><a href={"/reportDetails/"+base64_encode(preyear.toString())}>{getadminhr.length !== 0 ?
                                 getadminhr.map((list,index) => list.Year === preyear.toString()? list.TotalHours : ''): 0}</a></td>

                            </tr>
                        </thead>

                    </table>

                </div>


                </div>
                </div>
                </div>


            </div>
            </div>
            <Footer/>
        </div>
    );
};
export default AdminDashboard