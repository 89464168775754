import React from "react";

function Footer() {
    const style={
        'color':'white',
        'text-decoration': 'none',
    }
    const curYear = (new Date()).getFullYear();
    return (
            <div className="footer">
            <a style={style} href="https://collective-s.com/">Copyrights &copy;{curYear} All Rights Reserved</a>
        </div>
    );
}

export default Footer;